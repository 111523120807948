//import ar from './locale/ar';
//import ca from './locale/ca';
//import cs from './locale/cs';
//import da from './locale/da';
//import de from './locale/de';
//import el from './locale/el';
import en from './locale/en';
import es from './locale/es';
//import fa from './locale/fa';
//import fi from './locale/fi';
//import fr from './locale/fr';
//import he from './locale/he';
//import hi from './locale/hi';
//import hu from './locale/hu';
//import id from './locale/id';
//import it from './locale/it';
//import ja from './locale/ja';
//import ko from './locale/ko';
//import lv from './locale/lv';
//import ml from './locale/ml';
//import nl from './locale/nl';
//import no from './locale/no';
//import pl from './locale/pl';
//import pt from './locale/pt';
//import pt_BR from './locale/pt_BR';
//import ro from './locale/ro';
//import ru from './locale/ru';
//import sk from './locale/sk';
//import sv from './locale/sv';
//import ta from './locale/ta';
//import th from './locale/th';
//import tr from './locale/tr';
//import uk from './locale/uk';
//import vi from './locale/vi';
//import zh_CN from './locale/zh_CN';
//import zh_TW from './locale/zh_TW';
//import is from './locale/is';

export default {
  //ar,
  //ca,
  //cs,
  //da,
  //de,
  //el,
  en,
  es,
  //fa,
  //fi,
  //fr,
  //he,
  //hi,
  //hu,
  //id,
  //it,
  //ja,
  //ko,
  //ml,
  //lv,
  //nl,
  //no,
  //pl,
  //pt_BR,
  //pt,
  //ro,
  //ru,
  //sk,
  //sv,
  //ta,
  //th,
  //tr,
  //uk,
  //vi,
  //zh_CN,
  //zh_TW,
  //is,
};
